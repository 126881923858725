<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" clearable @change="Search"> </el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="医生">
                <el-select v-model="searchForm.state" placeholder="请选择" size="small" style="margin-right: 10px; width: 150px">
                  <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="">
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="订单号/患者名称" @keyup.enter.native="Search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
              <!-- <el-form-item>
                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData" worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                  <el-button type="danger" size="mini" class="el-icon-download">导出</el-button>
                </download-excel>
              </el-form-item> -->
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column prop="orderNum" label="订单编号"></el-table-column>
          <el-table-column prop="patientName" label="患者" align="center">
            <template slot-scope="scope">
              {{ scope.row.patientName ? scope.row.patientName : '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="diagnoseResult" label="诊断" align="center">
            <template slot-scope="scope">
              {{ scope.row.diagnoseResult ? scope.row.diagnoseResult : '-' }}
            </template></el-table-column
          >
          <el-table-column prop="doctorName" label="医生" align="center">
            <template slot-scope="scope">
              {{ scope.row.doctorName ? scope.row.doctorName : '-' }}
            </template></el-table-column
          >
          <el-table-column prop="totalPayMoney" label="总金额(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.totalPayMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="discountsMoney" label="优惠金额" align="center">
            <template slot-scope="scope">
              {{ scope.row.discountsMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="userPayMoney" label="应收金额" align="center">
            <template slot-scope="scope">
              {{ (scope.row.totalPayMoney - scope.row.discountsMoney).toFixed(2) }}
            </template>
          </el-table-column> -->
          <el-table-column prop="state" label="状态" align="center" width="150px">
            <template slot-scope="scope">
              <el-tag type="warning" size="small" v-if="scope.row.state == 0">待支付</el-tag>
              <el-tag type="success" size="small" v-if="scope.row.state == 1">已支付</el-tag>
              <el-tag type="info" size="small" v-if="scope.row.state == 2">支付中</el-tag>
              <el-tag type="warning" size="small" v-if="scope.row.state == 3 && !scope.row.isSelef">待发货</el-tag>
              <el-tag type="warning" size="small" v-if="scope.row.state == 3 && scope.row.isSelef">待取药</el-tag>
              <el-tag type="warning" size="small" v-if="scope.row.state == 4">取药完成</el-tag>
              <el-tag type="success" size="small" v-if="scope.row.state == 5">已发货</el-tag>
              <el-tag type="success" size="small" v-if="scope.row.state == 6">完成</el-tag>
              <el-tag type="success" size="small" v-if="scope.row.state == 7">已反馈</el-tag>

              <el-tag type="danger" size="small" v-if="scope.row.state == -1">已退费</el-tag>
              <el-tag type="warning" size="small" v-if="scope.row.state == -2">退费审核中</el-tag>
              <el-tag type="danger" size="small" v-if="scope.row.state == -3">拒绝退费</el-tag>
              <el-tag type="danger" size="small" v-if="scope.row.state == -4">退费完成</el-tag>
              <p v-if="scope.row.state == -3">{{ scope.row.auditRemark }}</p>
              <el-tag type="warning" size="small" v-if="scope.row.state == -5">取消订单</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="收费员" align="center"> </el-table-column>
          <el-table-column prop="paySuccessTime" label="收费日期" align="center" width="160px">
            <template slot-scope="scope">
              {{ scope.row.paySuccessTime | timefilters }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="toOrderInfo(scope.row)">详情</span>
              <span class="primary optionBtn" @click="toRefund(scope.row)" v-if="scope.row.orderType == 0 && (scope.row.state == 1 || scope.row.state == -4)">退费</span>
              <span class="warning optionBtn" @click="toCompleteDrug(scope.row)" v-if="(scope.row.state == 1 || scope.row.state == -3) && scope.row.isIncludeMZ">完成备药</span>
              <span class="primary optionBtn" @click="AddDelivery(scope.row)" v-if="scope.row.state == 3 && !scope.row.isSelef">发货</span>
              <span class="primary optionBtn" @click="toComplete(scope.row)" v-if="scope.row.state == 3 && scope.row.isSelef">完成取药</span>
              <span class="warning optionBtn" @click="showExpress(scope.row)" v-if="scope.row.state > 4 && !scope.row.isSelef">查看物流</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <!-- 发货 -->
    <el-dialog title="发货" :visible.sync="setDeliveryDialog" width="30%">
      <el-form class="dialog-form" :model="formDelivery" ref="formDelivery" label-width="150px" :rules="rules">
        <el-form-item label="快递公司" prop="expressCompany">
          <el-input autosize v-model="formDelivery.expressCompany" placeholder="请输入快递公司" maxlength="20" show-word-limit />
        </el-form-item>
        <el-form-item label="快递单号" prop="expressCode">
          <el-input autosize v-model="formDelivery.expressCode" placeholder="请输入快递单号" maxlength="20" show-word-limit />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseDelivery('formDelivery')">取 消</el-button>
        <el-button type="primary" @click="saveDelivery('formDelivery')">确 定</el-button>
      </div>
    </el-dialog>

    <el-drawer title="物流" :visible.sync="dialogExpress" :direction="direction" wrapperClosable>
      <div class="drawer-box">
        <div class="detail-item">
          <h5 class="detail-tit">物流信息</h5>
          <div class="status">
            <!-- 0快递收件(揽件)1.在途中 2.正在派件 3.已签收 4.派送失败 5.疑难件 6.退件签收 -->
            <el-tag size="small" type="info" v-if="expressData.deliverystatus == 0">揽件中</el-tag>
            <el-tag size="small" v-if="expressData.deliverystatus == 1">在途中</el-tag>
            <el-tag size="small" v-if="expressData.deliverystatus == 2">正在派件</el-tag>
            <el-tag size="small" type="success" v-if="expressData.deliverystatus == 3">已签收</el-tag>
            <el-tag size="small" type="warning" v-if="expressData.deliverystatus == 4">派送失败</el-tag>
            <el-tag size="small" type="warning" v-if="expressData.deliverystatus == 5">疑难件</el-tag>
            <el-tag size="small" type="danger" v-if="expressData.deliverystatus == 6">退件签收</el-tag>
          </div>
          <p><span>快递公司名称：</span>{{ expressData.expName }}</p>
          <p><span>快递单号：</span>{{ expressData.number }}</p>
          <p><span>快递公司电话：</span>{{ expressData.expPhone }}</p>
          <template v-if="expressData.deliverystatus >= 2">
            <p><span>快递员：</span>{{ expressData.courier }}</p>
            <p><span>快递员电话：</span>{{ expressData.courierPhone }}</p>
          </template>
        </div>
        <div class="detail-item">
          <el-timeline>
            <el-timeline-item v-for="(item, index) in expressData.list" :key="index" color="#67C23A" :timestamp="item.time | timefilters">
              <span class="status-color">{{ item.status }}</span>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient.js'
import { Order } from '@/components/HospitalDomain/ShopMall/Order'
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    var order = new Order(this.TokenClient, this.Services.Shopping)
    return {
      OutpatientDomain: outpatient,
      OrderDomain: order,
      headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      setDialog: false,
      searchForm: {
        keyWord: this.$route.query.orderNum ? this.$route.query.orderNum : '',
        datepicker: '',
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      statusOption: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '0',
          label: '待付款',
        },
        {
          value: '2',
          label: '待发货',
        },
        {
          value: '3',
          label: '已发货',
        },
        {
          value: '1',
          label: '已完成',
        },
        {
          value: '-999',
          label: '退单',
        },
        {
          value: '-1',
          label: '已取消',
        },
        {
          value: '-2',
          label: '已删除',
        },
      ],
      title: '余额明细',
      DataCount: 0,
      json_fields: {
        订单编号: {
          filed: 'key',
          callback: (val) => {
            return 'NO：' + val.key
          },
        },
        当前余额: 'name',
      },
      fileList: [],
      setDeliveryDialog: false,
      formDelivery: {
        mzOrderId: 0,
        expressCompany: '',
        expressCode: '',
      },
      dialogExpress: false,
      expressData: {
        number: '432578454379043', //快递单号
        type: 'YUNDA', //快递公司简称
        deliverystatus: '3', //快递状态 0快递收件 (揽件)1.在途中 2.正在派件 3.已签收 4.派送失败 5.疑难件 6.退件签收
        issign: '1', //是否签收 可能不准
        expName: '韵达快递', //快递公司名称
        expSite: 'www.yundaex.com', //快递公司官网
        expPhone: '95546', //快递公司电话
        courier: '', //投递员名称
        courierPhone: '0371-61844349', // 快递员电话
        updateTime: '2022-05-31T19:20:26', //快递轨迹信息最新时间
        takeTime: '1天21小时19分', //发货到收货消耗时长 (截止最新轨迹)
        logo: 'https://img3.fegine.com/express/yd.jpg', //快递公司LOGO
        list: [
          //快递详情
          {
            time: '2022-05-31T19:20:26',
            status: '【代收点】您的快件已签收，签收人在 郑州兴达锦绣园新街坊超市店(已签收签收人凭取货码签收) 领取，投诉电话: 0371 - 61844349',
          },
        ],
      },
      direction: 'rtl',
      rules: {
        expressCompany: [{ required: true, message: '请输入快递公司', trigger: 'blur' }],
        expressCode: [{ required: true, message: '请输入快递单号', trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    saveDelivery(formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.OutpatientDomain.MZDeliverGoods(
            _this.formDelivery,
            function (data) {
              _this.$message({
                message: '发货成功',
                type: 'success',
              })
              _this.setDeliveryDialog = false
              _this.getList()
            },
            function (error) {
              let jsonObj = JSON.parse(error.msg)
              _this.$message({
                message: jsonObj.msg,
                type: 'error',
              })
              console.log(error)
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    /**
     * 查看物流
     * @param {*} item
     */
    showExpress(item) {
      var _this = this
      _this.expressData = {}
      var expressCodeStr = item.expressCode
      if (expressCodeStr) {
        _this.dialogExpress = true
        _this.OrderDomain.ExpressQuery(
          expressCodeStr,
          function (data) {
            _this.dialogExpress = true
            _this.expressData = data.data
          },
          function (err) {
            _this.$message({
              type: 'error',
              message: '快递单号错误',
            })
            console.log(err)
          }
        )
      } else {
        _this.$message({
          type: 'error',
          message: '单号无效',
        })
      }
    },
    CloseDelivery(formName) {
      this.setDeliveryDialog = false
      this.$refs[formName].resetFields()
    },
    AddDelivery(item) {
      this.setDeliveryDialog = true
      this.formDelivery.mzOrderId = item.id
      this.formDelivery.expressCompany = ''
      this.formDelivery.expressCode = ''
    },
    toComplete(item) {
      var _this = this
      _this
        .$confirm('是否完成取药?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // type: 'warning'
        })
        .then(() => {
          let map = {
            mzOrderId: item.id,
          }
          _this.OutpatientDomain.MZConfirmReceipt(
            map,
            function (data) {
              _this.$message({
                message: '完成取药成功',
                type: 'success',
              })
              _this.getList()
            },
            function (error) {
              let jsonObj = JSON.parse(error.msg)
              _this.$message({
                message: jsonObj.msg,
                type: 'error',
              })
              console.log(error)
            }
          )
        })
        .catch(() => {})
    },
    toCompleteDrug(item) {
      var _this = this
      _this
        .$confirm('完成备药?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // type: 'warning'
        })
        .then(() => {
          _this.OutpatientDomain.DrugCompleteOrg(
            item.id,
            function (data) {
              _this.$message({
                message: '完成备药成功',
                type: 'success',
              })
              _this.getList()
            },
            function (error) {
              let jsonObj = JSON.parse(error.msg)
              _this.$message({
                message: jsonObj.msg,
                type: 'error',
              })
              console.log(error)
            }
          )
        })
        .catch(() => {})
    },
    toCreateOrder(item) {
      this.$router.push({
        path: './CreateOrder',
        query: {
          orderId: item.id,
        },
      })
    },
    toRefund(item) {
      this.$router.push({
        path: './ChargedOrRefund',
        query: {
          type: 3, //退费
          orderId: item.id,
        },
      })
    },
    toOrderInfo(item) {
      this.$router.push({
        path: './OrderInfo',
        query: {
          type: 1, //已收费
          orderId: item.id,
        },
      })
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList() {
      var _this = this
      _this.tableData = []
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.OutpatientDomain.DrugAlreadyPayment(
        item.keyWord,
        item.startTime,
        item.endTime,
        this.pageIndex,
        true,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search(event) {
      this.pageIndex = 1
      this.tableData = []
      this.getList()
    },
    // 导入数据
    handleExcel(file, fileList) {
      console.log(file.raw)
      let formData = new FormData() //声明一个FormDate对象
      formData.append('formFile', file.raw) //把文件信息放入对象中
    },
    //模板下载
    downloadTemplate() {
      let a = document.createElement('a')
      a.href = './drug_import_template_with_branch.xls'
      a.download = '药品模板.xlsx'
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    async fetchData() {
      var _this = this
      return await _this.getAllData()
    },
    getAllData() {
      var _this = this
      return new Promise((resolve, reject) => {
        // var item = _this.searchForm;
        // if (_this.searchForm.datepicker) {
        //   item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        //   item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
        // } else {
        //   item.startTime = "";
        //   item.endTime = "";
        // }
        // _this.BalanceDomain.BalanceDetail(
        //   item.startTime,
        //   item.endTime,
        //   item.keyWord,
        //   item.state,
        //   1,
        //   _this.DataCount,
        //   function (data) {
        //     resolve(data.data.results);
        //   },
        //   function (err) {
        resolve('')
        //     console.log(err);
        //   }
        // );
      })
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
.drawer-box {
  padding: 30px;
}
.el-drawer {
  background-color: #ffffff !important;
  width: 550px !important;
}
.el-drawer__body {
  overflow: auto;
}
.detail-item {
  position: relative;
  margin-bottom: 20px;
}

.detail-item .detail-tit {
  font-size: 18px;
}

.detail-item > p {
  margin: 5px 0;
}

.detail-item > p span {
  display: inline-block;
  width: 100px;
  text-align: right;
}

.detail-item .status {
  position: absolute;
  right: 5px;
  top: 5px;
}

.detail-item .status .text-orange {
  color: orange;
}

.detail-item .status .text-blue {
  color: rgb(47, 0, 255);
}

.detail-item .status p.text-danger {
  color: red;
}

.detail-item .status p.text-succes {
  color: forestgreen;
}
</style>
